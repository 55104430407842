@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&family=Jura:wght@300;400;500;600;700&display=swap');
body {
  margin: 0;
  line-height: 1.43;
  font-family: 'Barlow', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* //  touch-action: none; */
}
::-webkit-scrollbar {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

pre {
  background-color: #f7f7f7;
  padding: 10px;
  display: inline-block;
  width: 100%;
}
code {
  white-space: normal;
}
code span:last-child:selection {
  background-color: #f7f7f7;
}

sup {
  color: #ff7400;
  font-size: 10px;
  border: 1px solid #ffd8bd;
  border-radius: 2px;
  padding: 0 2px;
  font-weight: bold;
  margin-top: -12px;
  margin-left: 4px;
  transition: all 0.3s ease-in-out;
}
